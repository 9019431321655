@font-face {
  font-family: 'One Dot Condensed Bold';
  src: url('./fonts/OneDotCd-Bold.eot') format('embedded-opentype'),
    url('./fonts/OneDotCd-Bold.ttf') format('truetype'),
    url('./fonts/OneDotCd-Bold.woff') format('woff'),
    url('./fonts/OneDotCd-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'One Dot';
  src: url('./fonts/OneDot.eot');
  src: url('./fonts/OneDot.eot?#iefix') format('embedded-opentype'),
    url('./fonts/OneDot.woff') format('woff'),
    url('./fonts/OneDot.ttf') format('truetype');
}
